import { Link } from 'gatsby'
import React from 'react'

import Img from 'gatsby-image'

//import Logo from './pic.jpg'

const Sidebar = ({ siteMetadata, logo }) => (
  <>
    <aside className="sidebar">
      <header>
        <div className="about">
          <div className="cover-author-image">
            <Link to="/">
              <img
                src={logo.childImageSharp.fluid.srcWebp}
                alt={siteMetadata.author}
              />
            </Link>
          </div>
          <div className="author-name">{siteMetadata.author}</div>
          <p>{siteMetadata.description}</p>
          <p>
            <Link className="tag" to={`/tags/beans/`}>
              Bean reviews
            </Link>
          </p>
          <p>
            <Link className="tag" to={`/gear/`}>
              Gear reviews
            </Link>
          </p>
          <p>
            <Link className="tag" to={`/recommendations/`}>
              Recommendations
            </Link>
          </p>
          <p>
            <Link className="tag" to={`/origins/`}>
              beans by Origins
            </Link>
          </p>
          <p>
            <Link className="tag" to={`/roasters/`}>
              beans by Roasters
            </Link>
          </p>
          <p>
            <Link className="tag" to={`/flavors/`}>
              beans by Flavors
            </Link>
          </p>
        </div>
      </header>
      <footer>
        <section className="contact">
          <h3 className="contact-title">Contact me</h3>
          <ul>
            {siteMetadata.social.email && (
              <li>
                <a href={`mailto:${siteMetadata.social.email}`} target="_blank">
                  <i className="fa fa-envelope-o" aria-hidden="true" />
                </a>
              </li>
            )}
          </ul>
        </section>
        <div className="copyright">
          <p>
            {new Date().getFullYear()} &copy; {siteMetadata.author}
          </p>
        </div>
      </footer>
    </aside>
  </>
)

export default Sidebar
